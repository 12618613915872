import React from 'react';
import { Button, EmptyLayout, Paragraph as P } from '@periodica/ui-kit';
import { Link } from 'gatsby';
import { YandexTableau } from '@components/Layouts/YandexTableau';
import Layout from '../../components/Layouts/Layout';
import { OneColumn } from '../../components/Layouts/OneColumn';
import SEO from '../../components/Layouts/SEO';

export function Head() {
  return (
    <>
      <SEO
        title="Заказ отменен"
        description="Нам жаль, что так вышло. По каким-то причинам заказ был отменён, но мы надеемся, что вы ещё не раз вернетесь к нам."
      />
      <YandexTableau />
    </>
  );
}

function OrderApprovedPage() {
  return (
    <Layout>
      <OneColumn>
        <EmptyLayout
          title="Заказ отменен"
          description={
            <P size="inherit">
              Нам жаль, что так вышло. По&nbsp;каким-то причинам заказ был отменён, но
              мы&nbsp;надеемся, что&nbsp;вы&nbsp;ещё не&nbsp;раз вернетесь к&nbsp;нам.
            </P>
          }
        >
          <Button size="xLarge" variant="secondary" asChild>
            <Link to="/" className="order-success__button">
              Хорошо
            </Link>
          </Button>
        </EmptyLayout>
      </OneColumn>
    </Layout>
  );
}

export default OrderApprovedPage;
